import { template as template_32fa115846d044b68a0fe7424a8afefa } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_32fa115846d044b68a0fe7424a8afefa(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
