import { template as template_feeaa1f4fd7f452591b6e190b9c7c506 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_feeaa1f4fd7f452591b6e190b9c7c506(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
