import { template as template_3e4967a060d145ecbea23bc65509da2f } from "@ember/template-compiler";
const WelcomeHeader = template_3e4967a060d145ecbea23bc65509da2f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
