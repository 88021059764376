import { template as template_65185b31b54944149b63ce896a73f40c } from "@ember/template-compiler";
const SidebarSectionMessage = template_65185b31b54944149b63ce896a73f40c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
